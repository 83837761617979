
.footer .links ul a:not(.btn) {
    color: #54595F;
    font-weight: 600;
}


.footer .links ul a:not(.btn):hover {
    color: #ff0000;
    font-weight: 600;
}